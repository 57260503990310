import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";

const BoxWraper = styled(Box)`
  width: 100%;
`;
const Heading = styled(Typography)`
  font-family: syne-bold;
  font-size: 42px;
  color: #000;
  position: relative;
  z-index: 222;
  ::after {
    content: "";
    width: 250px;
    height: 12px;
    border-radius: 5px;
    background: #f3ba2f;
    display: block;
    position: absolute;
    // left: 10px;
    bottom: 3px;
    margin: auto;
    z-index: -21;
  }
  @media (max-width:900px){
    color: #fff;
  }
   @media (max-width:499px){
    font-size:24px;
    ::after{
      width:150px;
      bottom:1px;
    }
  }
`;
const SubHeading = styled(Typography)`
  margin: ${(props) => props.margin};
  font-family: syne-bold;
  font-size: 16px;
  color: #fccb00;
   @media(max-width:1099px){
    margin:${(props) => props.marginM};
  }
  @media(max-width:599px){
    font-size:12px;
  }

`;
const PTypo = styled(Typography)`
  margin: ${(props) => props.margin};
  font-size: 14px;
  font-family: syne-ragular;
  color: #fff;
  @media (max-width: 1099px) {
    margin: ${(props) => props.marginM};
  }
`;

const TokenImage = styled.img`
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: -40%;
  left: -25%;
  @media (max-width: 999px) {
    max-width: 500px;
    // top: -38%;
    // left: -30%;
  }
  @media (max-width: 799px) {
    max-width: 450px;
    top: -25%;
    left: -20%;
  }
  @media (max-width: 630px) {
    max-width: 420px;
    // top: -20%;
    // left: -40%;
  }
  @media (max-width: 599px) {
    max-width: 400px;
    // top: -10%;
    left: -15%;
  }
  @media (max-width: 499px) {
    max-width: 350px;
    top: -18%;
    left: -10%;
  }
`;
const PartImg = styled.img`
  width: 100%;
  margin: 105px 20px;
  max-width: 10px;
  @media (max-width: 799px) {
    margin: 105px 40px;
  }
`;
const InnerGrid = styled(Grid)`
  width: 100%;
`;
const InnerText = styled.span`
  margin: ${(props) => props.margint};
  color: #fff;
  font-family: syne-bold;
  font-size: 16px;
  @media(max-width:1099px){
    margin:${(props) => props.marginM};
  }
`;
export {
  Heading,
  TokenImage,
  PTypo,
  SubHeading,
  BoxWraper,
  PartImg,
  InnerGrid,
  InnerText,
};
