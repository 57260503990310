import React from "react";
import { ContainerWraper } from "../S1_header/style";
import {
  DotImg,
  Heading,
  ImgWraper,
  InnerGrid,
  Logo,
  LogoDiv,
  OuterGrid,
  SubHeading,
} from "./style";
import mainImg from "../../assets/S2_mainImg.png";
import dotImg from "../../assets/WhiteDots.png";
import etherscan from "../../assets/etherscan.png";
import uniswap from "../../assets/uniswap.png";
import dextool from "../../assets/dextool.png";
import { Grid } from "@mui/material";
const AboutSection = () => {
  return (
    <ContainerWraper id="About" margin="50px" marginb="0px">
      <OuterGrid container>
        <Grid item xs={12} sm={5} md={6} lg={7} sx={{display:"flex", justifyContent:"center"}}>
          <ImgWraper src={mainImg} data-aos="zoom-in-right"/>
        </Grid>
        <InnerGrid item xs={12} sm={7} md={6} lg={5}>
          <Heading variant="h3" data-aos="zoom-in-left">About Us</Heading>
          <SubHeading paragraph={true} data-aos="zoom-in-left">
            At Hero Inu, we are passionate about revolutionizing the crypto space
            by combining cutting-edge technology with the infectious energy of
            the canine world. Our team of talented developers, blockchain
            enthusiasts, and dog lovers have come together to create a project
            that embodies innovation, community, and boundless potential.
          </SubHeading>
          <DotImg src={dotImg} />
        </InnerGrid>
      </OuterGrid>
      <OuterGrid container justify="center">
        <Grid item xs={12}>
          <LogoDiv>
            <Logo src={etherscan} />
            <Logo src={uniswap} />
            <Logo src={dextool} />
          </LogoDiv>
        </Grid>
      </OuterGrid>
    </ContainerWraper>
  );
};

export default AboutSection;
