import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

const RightImg = styled.img`
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: -20%;
  right: -20%;
  @media (max-width: 899px) {
    max-width: 500px;
  }
  @media (max-width: 699px) {
    max-width: 400px;
  }
  @media (max-width: 599px) {
    max-width: 300px;
  }
  @media (max-width: 499px) {
    max-width: 200px;
  }
`;
const LeftImg = styled.img`
  width: 100%;
  max-width: 600px;
  position: absolute;
  left: -40%;
  @media (max-width: 899px) {
    max-width: 400px;
  }
  @media (max-width: 699px) {
    max-width: 300px;
  }
  @media (max-width: 599px) {
    max-width: 200px;
  }
  @media (max-width: 499px) {
    max-width: 100px;
  }
`;
const CenterImg = styled.img`
  margin-top: 100px;
  width: 100%;
  max-width: 80px;
`;
const InnerGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;
const InnerGrid2 = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const Heading = styled(Typography)`
  font-family: syne-bold;
  font-size: 42px;
  color: #fff;
  position: relative;
  z-index: 222;
  ::after {
    content: "";
    width: 280px;
    height: 12px;
    border-radius: 5px;
    background: #f3ba2f;
    display: block;
    position: absolute;
    // left: 10px;
    bottom: 7px;
    margin: auto;
    z-index: -21;
  }
  @media (max-width:499px){
    font-size:24px;
    ::after{
      width:180px;
      bottom: 3px;
    }
  }
`;
export { RightImg, Heading, CenterImg, InnerGrid, InnerGrid2, LeftImg };
