import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";

const RightImg = styled.img`
  width: 100%;
  max-width: 400px;
  position: absolute;
  top: 20%;
  right: -18%;
  @media (max-width: 899px) {
    max-width: 300px;
    top: 50%;
    right: -20%;
  }
  @media (max-width: 699px) {
    max-width: 200px;
    top: 70%;
    right: -20%;
  }
`;

const InnerGrid2 = styled(Grid)`
  width: 100%;
  margin: 0px 0 40px;
  color: #fff;
`;
const CRBox = styled(Box)`
  width: 100%;
  color: #fff;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  border-top: 1px solid white;
`;
export { RightImg, InnerGrid2, CRBox };
