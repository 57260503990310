import React from "react";
import { ContainerWraper } from "../S1_header/style";
import { Grid, Link } from "@mui/material";
import timeline from "../../assets/Timeline.png";
import {
  Heading,
  InnerGrid,
  InnerText,
  PartImg,
  PTypo,
  SubHeading,
} from "./style";

const TokenomicSection = () => {
  return (
      <ContainerWraper id="Tokenomics" margin="100px">
        <Grid container>
          <Grid item xs={12}>
            {/* <TokenImage src={shape2} /> */}
            <Heading variant="h3">Tokenomics</Heading>
          </Grid>
          <Grid container>
            <InnerGrid item xs={2} sm={3.5}>
              <SubHeading margin="100px 0" data-aos="zoom-in-left" data-aos-duration="2000">
                Unleash Financial Freedom!
              </SubHeading>
            </InnerGrid>
            <InnerGrid item xs={1}>
              <PartImg src={timeline} data-aos="zoom-in" data-aos-duration="2000"/>
            </InnerGrid>
            <InnerGrid item xs={9} sm={7}>
              <PTypo
                paragraph={true}
                margin="100px 300px 50px 0px"
                marginM="100px 0px 30px 50px"
                data-aos="zoom-in-left"
                data-aos-duration="2000"
              >
                Are you ready to unleash your financial freedom? Look no further
                than Hero Inu, the game-changer in the crypto world. Our vision is
                to create a community-driven ecosystem where investors have the
                opportunity to grow their wealth and participate in a vibrant,
                thriving economy.
              </PTypo>
              <SubHeading margin="20px 0" marginM="20px 50px" data-aos="zoom-in-left" data-aos-duration="2000">
                Contract: &nbsp;<Link sx={{textDecoration:"none"}} href="https://etherscan.io/token/0xa8865313a72d0142369e464b363be01ba587992c" traget="blank"><InnerText style={{wordBreak:"break-all"}}>0xa8865313a72d0142369e464b363be01ba587992c</InnerText></Link>
              </SubHeading>
              <InnerText marginM="0 50px" data-aos="zoom-in" data-aos-duration="2000">Tax:&nbsp;0/2</InnerText> <br />
              <InnerText marginM="0 50px" data-aos="zoom-in" data-aos-duration="2000">Symbol:&nbsp;HINU</InnerText> <br />
              <InnerText marginM="0 50px" data-aos="zoom-in" data-aos-duration="2000">Supply:&nbsp;888,888,888</InnerText>
            </InnerGrid>
          </Grid>
        </Grid>
      </ContainerWraper>
  );
};

export default TokenomicSection;
