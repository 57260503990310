import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import navLogo from "../../assets/navLogo.png";
import {
  Logo,
  NavBar,
  NavBox,
  NavLinks,
  NavLogo,
  NavTollBar,
} from "./style.js";
const drawerWidth = 240;
const navItems = [
  { title: "Home", link: "#Home" },
  { title: "About", link: "#About" },
  { title: "Tokenomics", link: "#Tokenomics" },
];

const DrawerAppBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Logo style={{ padding:"10px 0"}}  src={navLogo} size="50px"/>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton href={item.link} sx={{ textAlign: "center" }}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <NavBox>
      <CssBaseline />
      <NavBar component="nav">
        <NavTollBar>
          <Logo src={navLogo} size="50px"/>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {navItems.map((item) => (
              <NavLinks href={item.link} key={item} sx={{ color: "#000" }}>
                {item.title}
              </NavLinks>
            ))}
            <NavLogo href="https://t.me/heroinu_erc20">Join Telegram</NavLogo>
          </Box>
        </NavTollBar>
      </NavBar>
      <Box component="nav" sx={{ backgroundColor: "white" }}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </NavBox>
  );
};

export default DrawerAppBar;
