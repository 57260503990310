import React from "react";
import { InnerText } from "../S3_tokenomic/style";
import { ContainerWraper } from "../S1_header/style";
import { CenterImg, Heading, InnerGrid, InnerGrid2 } from "./style";
import logoGroup from "../../assets/logoGroup.png";
import { Grid } from "@mui/material";

const HTBSection = () => {
  return (
      <ContainerWraper margin="100px">
        <Grid container>
          <Grid item xs={12}>
            <Heading>HOW TO BUY</Heading>
          </Grid>
          <InnerGrid item xs={3}>
            <CenterImg src={logoGroup} data-aos="zoom-in-right" data-aos-duration="2000"/>
          </InnerGrid>
          <InnerGrid2 item xs={4} sx={{ m: "105px 0 0 30px" }} data-aos="zoom-in-left" data-aos-duration="2000">
            <InnerText>Create Your Wallet</InnerText>
            <InnerText>Deposit $Eth to MetaMask</InnerText>
            <InnerText> Visit Hero Inu on Uniswap</InnerText>
            <InnerText>Swap $Eth to $HINU</InnerText>
          </InnerGrid2>
        </Grid>
      </ContainerWraper>
  );
};

export default HTBSection;
