import "./App.css";
import MainPage from "./pages/P1_main";

function App() {
  return (
      <MainPage />
  );
}

export default App;
