import React, { useEffect } from "react";
import {
  ContainerWraper,
  Heading,
  ImgWraper,
  SubHeading,
  InnerHeading,
  CartButton,
  HomeInnerDiv,
  BuyButton,
  BtnLogo,
  InnerGrid,
  ParentGrid,
} from "./style";
import mainImg from "../../assets/S1_mainImg.png";
import arrow from "../../assets/arrow.png";
import { Grid } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";

const HeaderSection = () => {
  useEffect(() => {
    AOS.init({duration: 1200,});
    AOS.refresh();
  }, []);
  return (
      <ContainerWraper id="Home">
        <ParentGrid container>
          <InnerGrid item xs={12} sm={6}>
            <Heading variant="h3" data-aos="zoom-in-right">
              Reach for the Stars with &nbsp;
              <InnerHeading data-aos="fade-right">Hero Inu!</InnerHeading>
            </Heading>
            <SubHeading paragarph={true} data-aos="fade-right">
              Welcome to Hero Inu, where the worlds of cryptocurrency and adorable
              dogs collide in an exciting new project! Get ready to embark on an
              extraordinary journey into the realm of decentralized finance,
              guided by the spirit of CZ, the visionary founder of the largest
              crypto exchange, and the undeniable charm of Inu, the beloved
              Japanese symbol of loyalty and protection.
            </SubHeading>
            <HomeInnerDiv >
              <BuyButton href="https://app.uniswap.org/#/swap?outputCurrency=0xa8865313a72D0142369E464B363BE01Ba587992C&chain=ethereum" target="blank" >
                Buy now
                <BtnLogo src={arrow}></BtnLogo>
              </BuyButton>
              <CartButton href="https://www.dextools.io/app/en/ether/pair-explorer/0xed453f09d0ce9c06cbbb5fa1002d182cd5fc4760" target="blank">
                View chart
                <BtnLogo src={arrow}></BtnLogo>
              </CartButton>
            </HomeInnerDiv>
          </InnerGrid>
          <Grid item xs={12} sm={6} sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
              <ImgWraper data-aos="zoom-in" src={mainImg}></ImgWraper>
        </Grid>
        </ParentGrid>
      </ContainerWraper>
  );
};

export default HeaderSection;
