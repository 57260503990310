import { Grid } from "@mui/material";
import React from "react";
import { InnerGrid, PTypo, SubHeading } from "../S3_tokenomic/style";
import { Heading } from "../S4_HowToBuy/style";
import { ContainerWraper } from "../S1_header/style";
import telegram from "../../assets/telegram.png";
import twiter from "../../assets/twiter.png";
import mediam from "../../assets/mediam.png";
import { CRBox, InnerGrid2 } from "./style";
import { Logo, NavLogoLink } from "../../components/navBar/style";

const GITSection = () => {
  return (
    <>
      <ContainerWraper margin="100px">
        <Grid container>
          <Grid item xs={12}>
            <Heading>Get In Touch</Heading>
          </Grid>
          <InnerGrid item xs={10}>
            <SubHeading margin="30px 0px">Disclosures and Legalease</SubHeading>
            <PTypo data-aos="zoom-out-up" data-aos-duration="2000">
              The information provided on this website is for educational and
              informational purposes only. Cryptocurrency investments carry
              inherent risks, and individuals should conduct their own research
              and consult with a qualified financial advisor before making any
              investment decisions. Hero Inu team members, partners, or
              affiliates are not responsible for any financial losses incurred
              as a result of investing in Hero Inu or any other
              cryptocurrencies. Please invest responsibly and be aware of the
              volatility and speculative nature of the cryptocurrency market.
            </PTypo>
            <SubHeading data-aos="zoom-out-up" data-aos-duration="2000" margin="30px 0px">
              Email: <span style={{color:"#fff"}}> info@hinu.finance</span>
            </SubHeading>
          </InnerGrid>
          <InnerGrid2 item xs={12}>
            <NavLogoLink href="https://t.me/heroinu_erc20" target="blank">
              <Logo src={telegram} size="40px" />
            </NavLogoLink>
            <NavLogoLink
              href="https://twitter.com/heroinu_erc20"
              target="blank"
            >
              <Logo src={twiter} size="40px" />
            </NavLogoLink>
            <NavLogoLink href="">
              <Logo src={mediam} size="40px" />
            </NavLogoLink>
          </InnerGrid2>
        </Grid>
      </ContainerWraper>
      <CRBox>© 2023 by Hero Inu. All rights reserved!</CRBox>
    </>
  );
};

export default GITSection;
