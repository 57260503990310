import React from "react";
import HeaderSection from "../../Sections/S1_header";
import { BoxWraper } from "./style";
import DrawerAppBar from "../../components/navBar";
import AboutSection from "../../Sections/S2_about";
import TokenomicSection from "../../Sections/S3_tokenomic";
import HTBSection from "../../Sections/S4_HowToBuy";
import GITSection from "../../Sections/S5_GetInTouch";
const MainPage = () => {
  return (
    <>
      <DrawerAppBar />
      <BoxWraper>
        <HeaderSection />
        <AboutSection />
        <TokenomicSection />
        <HTBSection />
        <GITSection />
      </BoxWraper>
    </>
  );
};

export default MainPage;
