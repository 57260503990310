import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

const ImgWraper = styled.img`
  width: 100%;
  max-width: 520px;
  @media (max-width: 1099px) {
    max-width: 450px;
  }
  @media (max-width: 999px) {
    max-width: 400px;
  }
  @media (max-width: 799px) {
    max-width: 350px;
  }
  @media (max-width: 699px) {
    max-width: 300px;
  }
  @media (max-width: 599px) {
    // margin-bottom: 100px;
  }
`;

const OuterGrid = styled(Grid)`
  width: 100%;
  align-items:center;
`;
const InnerGrid = styled(Grid)`
  width: 100%;
  margin-top: 130px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 599px) {
    margin-top: 30px;
  }
`;
const Heading = styled(Typography)`
  font-family: syne-bold;
  font-size: 42px;
  color: #fff;
  z-index: 222;
  ::after {
    content: "";
    width: 200px;
    height: 11px;
    border-radius: 5px;
    background: #f3ba2f;
    display: block;
    position: relative;
    bottom: 17px;
    margin: auto;
    z-index: -21;
  }
  @media (max-width:499px){
    font-size:24px;
    ::after{
      width:130px;
      bottom:10px;
    }
  }
`;
const SubHeading = styled(Typography)`
  margin-top: 10px;
  margin-right: 40px;
  font-size: 16px;
  font-family: syne-ragular;
  color: #fff;
  @media (max-width: 599px) {
    margin-right: 0px;
  }
`;

const DotImg = styled.img`
  width: 100%;
  margin-top: 100px;
  max-width: 280px;
  position: absolute;
  left: 50%;
  top: 100%;
  @media (max-width: 1299px) {
    max-width: 270px;
    left: 30%;
  }
  @media (max-width: 899px) {
    max-width: 250px;
    left: 20%;
  }
`;

const LogoDiv = styled.div`
  width: 100%;
  margin-top: 200px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 30px 50px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 3px 10px #eae9e0;
  justify-content: space-between;
  z-index: 2222;
  @media (max-width: 599px) {
    flex-direction: column;
  }
`;
const Logo = styled.img`
  width: 100%;
  max-width: 150px;
  @media (max-width: 1099px) {
    max-width: 100px;
  }
  @media (max-width: 599px) {
    margin: 10px 0;
  }
`;

export {
  ImgWraper,
  OuterGrid,
  InnerGrid,
  Heading,
  SubHeading,
  DotImg,
  LogoDiv,
  Logo,
};
