import styled from "@emotion/styled";
import { Box } from "@mui/material";

const BoxWraper = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;
  // background-color: #000;
`;
const AboutImage = styled.img`
  width: 100%;
  max-width: 450px;
  position: absolute;
  top: 90%;
  left: -30%;
  @media (max-width: 599px) {
    max-width: 350px;
    top: 100%;
  }
`;
export { BoxWraper, AboutImage };
