import styled from "@emotion/styled";
import "../../App.css";
import { AppBar, Box, Button, Link, Toolbar } from "@mui/material";
const NavBar = styled(AppBar)`
  background: white !important;
  padding: 5px 0;
  border: none;
  position: relative;
  box-shadow: none;
`;
const Logo = styled.img`
  width: ${(props) => props.size};
`;
const NavLogo = styled(Link)`
  font-family: syne-bold;
  font-size: 14px;
  width: 160px;
  height: 35px;
  border-radius: 10px;
  display: flex;
  text-decoration: none !important;
  border: none;
  margin: 0 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  background-color: #f3ba2f;
`;
const NavBox = styled(Box)`
width:100%;
  display: flex;
  backgroundcolor: white;
`;
const NavTollBar = styled(Toolbar)`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  padding:0 100px !important;
  @media(max-width:799px){
    padding:0 10px !important;
  }
`;
const NavLinks = styled(Button)`
  font-family: syne-bold;
  text-transform: none;
  font-size: 14px;
  background-color: white;
  margin: 0 20px;
  @media (max-width: 799px) {
    margin: 0 0px;
  }
  :hover {
    color: #ffd803;
    background-color: #fff7cc;
  }
`;
const NavLogoLink = styled(Link)`
  border: none;
  padding: 0;
  margin: 10px 10px !important;
`;

const SideMenuBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;
export {
  NavBar,
  NavLogo,
  NavLogoLink,
  Logo,
  NavTollBar,
  NavLinks,
  SideMenuBox,
  NavBox,
};
