import styled from "@emotion/styled";
import "../../App.css";
import { Button, Container, Grid, Typography } from "@mui/material";

const ContainerWraper = styled(Container)`
  position: relative;
  width: 100%;
  padding: 0 50px !important;
  margin-top: ${(props) => (props ? props.margin : "0px")};
  margin-bottom:${(props) => (props ? props.marginb : "0px")};
`;

const InnerGrid = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ImgWraper = styled.img`
  width: 100%;
  margin-top:10px;
  max-width: 100%;
  /* @media (max-width: 1099px) {
    max-width: 450px;
  }
  @media (max-width: 999px) {
    max-width: 400px;
  }
  @media (max-width: 799px) {
    max-width: 350px;
  }
  @media (max-width: 699px) {
    max-width: 300px;
  }
  @media (max-width: 599px) {
   
    max-width: 160px;
  } */
`;
const Heading = styled(Typography)`
  margin-top: 70px;
  font-size: 56px;
  position: relative;
  font-family: syne-ragular;
  color: #fff;
  @media (max-width: 1099px) {
    font-size: 44px;
  }
  @media (max-width: 899px) {
    font-size: 38px;
  }
  @media (max-width: 799px) {
    font-size: 32px;
  }
  @media (max-width: 599px) {
    margin-top: 20px;
    font-size: 28px;
  }
`;
const InnerHeading = styled.span`

  font-size: 56px;
  position: relative;
  font-family: syne-ragular;
  color: #fff;
  position:relative;
  z-index:222;
    //   text-decoration-line: underline;
    // text-decoration-color: #f3ba2f;
  ::after {
    content: "";
    width: 198px;
    height: 8px;
    border-radius: 5px;
    background: #f3ba2f;
    display: block;
    position: absolute;
    left: 0%;
    bottom: 15px;
    z-index: -2;
    margin: auto;
  }
  @media (max-width: 1099px) {
    font-size: 46px;
    ::after {
      width: 174px;
      // right: 31%;
      bottom: 10px;
    }
  }
  @media (max-width: 899px) {
    font-size: 40px;
    ::after {
      width: 150px;
      // right: 15%;
      bottom: 8px;
    }
  }
  @media (max-width: 799px) {
    font-size: 34px;
    ::after {
      width: 130px;
      // right: 13%;
      bottom: 8px;
    }
  }
  @media (max-width: 699px) {
    ::after {
      width: 110px;
      // right: 0%;
      bottom: 6px;
    }
  }
`;

const SubHeading = styled(Typography)`
  margin: 50px 100px 50px 0;
  // margin-right: 100px;
  font-size: 16px;
  font-family: syne-ragular;
  color: #BFBFBF;
  // @media (max-width: 1099px) {
  //   margin-right: 200px;
  // }
  // @media (max-width: 899px) {
  //   margin-right: 150px;
  // }
  // @media (max-width: 799px) {
  //   margin-right: 100px;
  // }
  @media (max-width: 699px) {
    margin-right: 0px;
  }
`;
const ParentGrid = styled(Grid)`
  display:flex;
  @media(max-width:599px){

    flex-direction: column-reverse;
  }

`
const HomeInnerDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 499px) {
    flex-direction: column;
    align-items: left;
  }
`;
const CartButton = styled(Button)`
  font-family: syne-bold;
  variant: outlined;
  text-transform: none;
  border: 2px solid #fff;
  width: 150px;
  height: 38px;
  color: white;
  font-size: 12px;
  padding: 0 10px;
  font-weight: 500;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 20px;
`;
const BuyButton = styled(Button)`
  font-family: syne-bold;
  text-transform: none;
  variant: filled;
  justify-content: space-between;
  width: 150px;
  height: 38px;
  background-color: #f3ba2f;
  color: #000;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  margin: 20px 20px 0 0px;
  z-index: 11;
  :hover {
    background-color: #f3ba2f;
  }
`;

const BtnLogo = styled.img`
  width: 100%;
  max-width: 45px;
`;

export {
  ContainerWraper,
  ImgWraper,
  Heading,
  InnerHeading,
  SubHeading,
  HomeInnerDiv,
  CartButton,
  BuyButton,
  BtnLogo,
  InnerGrid,
  ParentGrid
};
